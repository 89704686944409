import React from 'react'
import { withRouter } from 'react-router-dom';

import QRCode from 'qrcode.react';
import Echo from 'laravel-echo';
import socketIOClient from 'socket.io-client';

import { message, Spin } from 'antd';
import { AuthAPI } from '../../apis/AuthApi'
import Separator from '../../components/Separator';

class QRCodeResult extends React.Component {

    state = {
        data: {
            token: "123"
        },
        isFetching : false,
        isExpired: false
    };

    componentDidMount() {
        this.initQRCode();
    };

    initQRCode = () => {
        this.setState({ isFetching: true });

        AuthAPI.showQRCode().then(res => {
            this.setState({ data: res.data }, () => {
                this.initListener();
            });
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            this.setState({ isFetching: false });
        })
    };

    initListener = () => {
        const { data } = this.state;
        const { history } = this.props;

        window.io = socketIOClient;

        const echo = new Echo({
            broadcaster: "socket.io",
            host: "https://admin.hubbedlearn.com"
        });


        echo.channel("login.channel." + data.token).listen(".login.on.scan", (e) => {
            const { session } = e;
            
            this.setState({ isFetching: true });

            AuthAPI.loginWithQRCode(session).then(res => {
                message.success("Login Success");
                localStorage.setItem("access_token", res.data.accessToken);
                history.push("/");
            })
            .catch(Err => {
                console.log(Err);
            })
            .finally(() => {
                this.setState({ isFetching: false });
            })
        });
    };

    render() {
        const { isFetching, data } = this.state;

        return (
            <div style={{
                width : "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <div style={{ position: "relative" }}>
                    <QRCode value={data.token} size={320} includeMargin />
                    {
                        isFetching ?
                            <div style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: 320,
                                width: 320,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(0,0,0,0.1)",
                                color: "white"
                            }}>
                                <Spin size="large" spinning={isFetching}/>
                            </div>
                        :null
                    }
                </div>
                <Separator />
                <h1>Login With QR Code</h1>
                <span style={{
                    width: "100%",
                    justifyContent: "center"
                }}>Scan this QR Code with <strong>HubbedLearn Mobile App</strong> to login instantly</span>
            </div>
        );
    }
}

export default withRouter(QRCodeResult);