import React from 'react';

const Container = (props) => {
    return (
        <div style={{
            padding: "12px"
        }}>
            {props.children}
        </div>
    )
}

export default Container;