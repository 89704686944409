import React from 'react';
import { Layout, Table, Button, Tag, Card } from 'antd';
import Header from '../../components/Header';
import { ProctorApi } from '../../apis/ProctorApi';
import moment from 'moment';
import Container from '../../components/Container';
import { connect } from 'react-redux';
import challengeAPI from '../../apis/challenge';
import Separator from '../../components/Separator';

const { Content } = Layout;

class AssignedTest extends React.Component {

    state = {
        isFetching: false,
        data: []
    }

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: text => {
                return text;
            }
        },
        {
            title: 'Start At',
            dataIndex: 'startDate',
            key: 'startDate',
            render: text => {
                return moment(text).format("YYYY-MM-DD");
            }
        },
        {
            title: 'End At',
            dataIndex: 'endDate',
            key: 'endDate',
            render: text => {
                return moment(text).format("YYYY-MM-DD");
            }
        },
        {
            title: "Allowed Time",
            render: (text, record, index) => {
                return `${record.requiredTime} Mins`;
            }
        },
        {
            title: "Status",
            render: (text, record) => {
                return <Tag color="green">LIVE</Tag>
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                if (record.attempts < record.numberOfAttempt) {
                    return (<Button onClick={() => this.joinStream(record.id)}>Join Test</Button>);
                }

                return <Tag color="green">Test Taken</Tag>;
            },
        },
    ];

    componentDidMount() {
        this.getAssignedTests();
    }

    joinStream = (id) => {
        const { profile, history } = this.props;

        let roleExists = profile.roles.find(x => x.name.toLowerCase() === "proctor");

        if (roleExists) {
            history.push(`/proctor/${id}/stream`);
        } else {
            this.setState({
                isFetching: true
            })
            challengeAPI.joinTestSession(id).then(res => {

                let token = res.data.session.token;

                challengeAPI.startTestWithToken({
                    token
                }).then(res => {
                    history.push(`/challenge/${token}/start`);
                })
                    .catch(err => {
                        throw err;
                    })
            })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.setState({
                        isFetching: false
                    })
                })
        }
    }

    getAssignedTests = () => {
        this.setState({
            isFetching: true
        });

        const { profile } = this.props;

        let roleExists = profile.roles.find(x => x.name.toLowerCase() === "proctor");

        if (roleExists) {
            ProctorApi.getAssignedTest().then(res => {
                this.setState({
                    data: res.data.tests
                })
            })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.setState({
                        isFetching: false
                    })
                })
        } else {
            challengeAPI.getAssignedTest().then(res => {
                this.setState({
                    data: res.data.tests
                });
            })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.setState({
                        isFetching: false
                    });
                })
        }
    }

    render() {

        const { data, isFetching } = this.state;

        return (
            <Layout>
                <Content>
                    <Header title="Assigned Test" />
                    <Container>
                        <div>
                            <div className='d-none d-lg-flex row w-100 border-bottom border-top py-3 bg-secondary text-light'>
                                <div className='col-12 col-sm-6 col-lg-2 text-left'>
                                    Name
                                </div>
                                <div className='col-12 col-sm-6 col-lg-2 text-left'>
                                    Start At
                                </div>
                                <div className='col-12 col-sm-6 col-lg-2 text-left'>
                                    End At
                                </div>
                                <div className='col-12 col-sm-6 col-lg-2 text-left'>
                                    Allowed Time
                                </div>
                                <div className='col-12 col-sm-6 col-lg-2 text-left'>
                                    Status
                                </div>
                                <div className='col-12 col-sm-6 col-lg-2 text-left'>
                                    Action
                                </div>
                            </div>
                            {
                                !isFetching &&
                                <>
                                    {
                                        data.map((n, i) => {
                                            return (
                                                <>
                                                    <Card className="d-block d-lg-none mb-2">
                                                        <div className='col-12 text-center mb-2'>
                                                            <b>{n.name}</b> <Tag className='ms-2' color="green">LIVE</Tag>
                                                        </div>
                                                        <div className='col-12 text-center'>
                                                            <span className='text-primary'>Allowed Time : {n?.requiredTime || 0} Mins</span>
                                                        </div>
                                                        <Separator size={60} />
                                                        <div className='col-12 text-center mb-2'>
                                                            {
                                                                n.attempts < n.numberOfAttempt ?
                                                                    <Button onClick={() => this.joinStream(n.id)} type="primary" className="rounded">Join Test</Button> :
                                                                    <Button disabled={true} className="btn-disabled">Completed</Button>
                                                            }
                                                        </div>
                                                    </Card>
                                                    {
                                                        /* DESKTOP VIEW */
                                                    }
                                                    <div key={i} className='d-none d-lg-flex row mb-3 mb-lg-0 border-lg-bottom w-100 border-bottom border-top py-3'>


                                                        <div className='d-none d-lg-block col-lg-2 text-left mb-2 mb-lg-0'>
                                                            {n.name}
                                                        </div>
                                                        <div className='d-none d-lg-block col-lg-2 text-left mb-2 mb-lg-0'>
                                                            <span className="d-block d-lg-none">Start At</span>
                                                            {moment(new Date(n.startDate)).format("MMM Do, YYYY")}
                                                        </div>
                                                        <div className='d-none d-lg-block col-lg-2 text-left mb-2 mb-lg-0'>
                                                            <span className="d-block d-lg-none">End At</span>
                                                            {moment(new Date(n.endDate)).format("MMM Do, YYYY")}
                                                        </div>
                                                        <div className='d-none d-lg-block col-lg-2 text-left mb-2 mb-lg-0'>
                                                            <span className="d-block d-lg-none">Allowed Time</span>
                                                            {n?.requiredTime || 0} Mins
                                                        </div>
                                                        <div className='d-none d-lg-block  col-lg-2 text-left mb-2 mb-lg-0'>
                                                            <span className="d-block d-lg-none">Status</span>
                                                            <Tag color="green">LIVE</Tag>
                                                        </div>
                                                        <div className='d-none d-lg-block  col-lg-2 text-left mb-2 mb-lg-0'>
                                                            {
                                                                n.attempts < n.numberOfAttempt ?
                                                                    <Button onClick={() => this.joinStream(n.id)}>Join Test</Button> :
                                                                    <Tag color="green">Completed</Tag>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }

                        </div>

                    </Container>
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.general.profile
    }
}

export default connect(mapStateToProps, null)(AssignedTest);