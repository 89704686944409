import {_fetch} from '../helpers/_fetch';

const url ='/test';

export const ScheduleAPI = {
    verifyURL: (body) => {
        return _fetch(`/auth/test/qrcode/varify/url`, {
            body,
            method: "POST"
        });
    },
    auth: (body) => {
        return _fetch('/auth/test/qrcode/login', {
            body, 
            method: "POST"
        })
    },
    validateScheduleTime: (testScheduleId) => {
        return _fetch(`${url}/${testScheduleId}/verify/time`, {
            method: "POST"
        })
    },
    getStreamingChannelName: (body) => {
        return _fetch(`${url}/streaming/channel`, {
            method: 'POST',
            body
        });
    },
    getStreamingChannelNameBySchedule: (body) => {
        return _fetch(`${url}/streaming/channel/sharing/code`, {
            method: 'POST',
            body
        })
    }
};