import React from 'react';
import {
	HomeFilled,
	ReadFilled,
	ContactsFilled,
	UserOutlined,
    ScheduleOutlined,
} from '@ant-design/icons';

const items = [
    {
        label: "Assigned Test",
        icon: <ScheduleOutlined />,
        link: "/assigned/test",
        allowedRoles: ['student', 'proctor']
    },
	{
		label: "Account",
        icon: <UserOutlined />,
		children: [
			{
				label: "Terms of Use",
				link: "/terms"
			}
		]
	}
];

const color = {
    primary: "#0CB0CF",
    secondary: "#8E8E93",
};

export { items, color };