import axios from 'axios';

const baseURL = `${window.appSettings.resource_url}/api`;

const getAuth = () => {
    let accessToken = localStorage.getItem("access_token");

    return `Bearer ${accessToken}`;
}

export const _fetch = async (url, options = {
    method: "GET",
    body: {}
}) => {

    const authorization = getAuth();

    const request = {
        method: options.method === undefined ? "POST" : options.method,
        baseURL,
        url,
        headers : {
            "Authorization": authorization,
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    };

    if (request.method === "POST" || request.method === "PUT") {
        request.data = options.body;
    };

    try {
        const res = await axios(request);

        if (res.status >= 200 && res.status < 400) {
            return res;
        }

    } catch (error) {

        // console.log(error, error.response);
        if (error.response && error.response.status === 401) {
            localStorage.removeItem("access_token");
            window.location.href = "/#/login";
        }

        throw error;
    }
}