import actionTypes from '../../actions/actionType';

const general = (
	state = {
		loading: false,
		isActionActive: false,
		profile: {
			roles: []
		},
		temporaryData: [],
	}, action ) => {
	switch (action.type) {
		case actionTypes.SAVE_PROFILE:
			return Object.assign({}, state, {
				profile: action.profile,
			});
		case actionTypes.TOGGLE_ACTION:
			return Object.assign({}, state, {
				isActionActive: action.isActionActive,
			});
		case actionTypes.SAVE_TEMPORARY_DATA:
			return Object.assign({}, state, {
				temporaryData: action.temporaryData,
			});
		case actionTypes.TOGGLE_LOADING:
			return Object.assign({}, state, { loading: !state.loading });
		case actionTypes.PROFILE:
			return Object.assign({}, state, { profile: action.payload });
		default:
			return state;
	}
};

export default general;
