import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const EnsureLoginRoute = (props) => {

    const [loggedIn, setLoggedIn] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const ensureLogin = () => {
        let token = localStorage.getItem('access_token');
        if (token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }

        setIsValid(true);
    }

    useEffect(() => {
        ensureLogin();
    }, [])

    const Component = props.component;
    return <React.Fragment>
        {
            isValid ? 
            (
                loggedIn ? 
                    <Route {...props.rest} render={(innerProps) =>
                        <Component {...innerProps} />
                    } />
                :
                    <Redirect to={`/login?returnUrl=asd`}/>
            )
            :
            null
        }
    </React.Fragment>;
}

export default EnsureLoginRoute;