import {_fetch} from '../helpers/_fetch';

const url ='/proctor';

export const ProctorApi = {
    getAssignedTest: () => {
        return _fetch(`${url}/assigned/test`);
    },
    getChannelNameByScheduleId: (body) => {

        return _fetch(`${url}/streaming/channel`, {
            body,
            method: "POST"
        });
    }
};