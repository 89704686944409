import React, { Suspense, useEffect, useState } from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import { Layout, Button, Dropdown, Space } from 'antd';
import { DownOutlined, LogoutOutlined, MenuFoldOutlined, } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';

import { items } from '../helpers/constant';
import EnsureLoginRoute from './Auth/EnsureLoggedInRoute';
import Header from './Header';
import Loading from './Loading';
import MenuItemList from './MenuItemList';

// import logo from '../assets/logo-arido.svg';
import logo from '../assets/logo-hubbed-learn.png';
import 'antd/dist/antd.css';
import AssignedTest from '../screens/AssignedTest';
import { AuthAPI } from '../apis/AuthApi';
import { connect } from 'react-redux';

import { saveProfile } from '../actions/general';


const { Sider, Content, Footer } = Layout;


const Dashboard = Loadable({
	loader: () => import('../screens/Dashboard'),
	loading: Loading
});

const Learning = Loadable({
	loader: () => import('../screens/Learning'),
	loading: Loading
});

const Challenge = Loadable({
	loader: () => import('../screens/Challenge'),
	loading: Loading
});

const Hub = Loadable({
	loader: () => import('../screens/Hub'),
	loading: Loading
});


const MainLayout = (props) => {
	let match = useRouteMatch();
	const { history, location } = props;

	const [collapsed, setCollapsed] = useState(false);
	const [logoutPromptVisible, setLogoutPromptVisible] = useState(false);
	const [profile, setProfile] = useState({
		roles: []
	});

	const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);


	const toggleSidebar = () => {
		setCollapsed(!collapsed);
	};

	const toggleLogoutPrompt = () => {
		setLogoutPromptVisible(!logoutPromptVisible);
	};

	const logout = () => {
		localStorage.removeItem("access_token");
		toggleLogoutPrompt();

		history.push("/login");
	};

	const getProfile = () => {
		AuthAPI.getProfile().then(res => {
			console.log(res.data);
			props.saveProfile(res.data);
			setProfile(res.data);
		})
			.catch(err => {
				console.log(err);
			})
	};

	const handleResize = () => {

		setWindowInnerWidth(window.innerWidth);

	}

	useEffect(() => {
		getProfile();
		window.addEventListener('resize', handleResize)
	}, [])

	const renderActions = () => {
		return (
			<>
				<Dropdown overlay={<MenuItemList items={filterSidebarMenu()} />}>
					<Button
						icon={<MenuFoldOutlined />}
					/>
				</Dropdown>
				<Button
					type="primary"
					shape="circle"
					icon={<LogoutOutlined />}
					onClick={toggleLogoutPrompt}
				/>
			</>

		);
	};

	const isRoot = location.pathname === "/";
	if (isRoot) return <Redirect to={"/assigned/test"} />;

	const filterSidebarMenu = () => {
		let newItems = [];


		items.map(item => {
			if (item.allowedRoles) {
				let allowedRoles = item.allowedRoles;

				for (let i = 0; i < allowedRoles.length; i++) {
					let exists = profile.roles.find(x => x.name.toLowerCase() == allowedRoles[i]);

					if (exists) {
						newItems.push(item);
						break;
					}
				}
			} else {
				newItems.push(item);
			}
		})

		return newItems;
	}

	return (
		<Suspense fallback={"...loading"}>
			<Layout style={{ minHeight: "100vh" }}>
				{
					windowInnerWidth > 992 &&
					<Sider
						collapsible collapsed={collapsed} onCollapse={toggleSidebar} style={{
							overflow: 'auto',
							height: '100vh',
							position: 'fixed',
							left: 0,
						}}>
						<div className="logo">
							<img className="company-logo" src={logo} alt="logo" />
						</div>
						<MenuItemList items={filterSidebarMenu()} />
					</Sider>
				}

				<Layout style={{
					paddingLeft: `${windowInnerWidth > 992 ? collapsed ? "80px" : "200px" : "0px"}`
				}}>
					<Content>
						<Header renderActions={renderActions} />
						<Switch>
							<EnsureLoginRoute path={`${match.url}dashboard`} component={Dashboard} />

							<EnsureLoginRoute path={`${match.url}learning`} component={Learning} />

							<EnsureLoginRoute path={`${match.url}challenge`} component={Challenge} />

							<EnsureLoginRoute path={`${match.url}hub`} component={Hub} />

							<EnsureLoginRoute path={`${match.url}assigned/test`} component={AssignedTest} />
						</Switch>
					</Content>

					<Footer style={{
						padding: "10px",
						height: "48px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}>All Right Reserved ©2022 Created by HubbedLearn</Footer>
				</Layout>
			</Layout>
			<Modal
				title="Logout"
				visible={logoutPromptVisible}
				onOk={logout}
				onCancel={toggleLogoutPrompt}
				okText="Logout"
				cancelText="Cancel"
			>
				Are you sure to logout ?
			</Modal>
		</Suspense>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		saveProfile: (profile) => {
			dispatch(saveProfile(profile));
		}
	};
};

const mapStateToProps = state => {
	return {
		profile: state.general.profile
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);