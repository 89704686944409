import  { _fetch } from '../helpers/_fetch';

const baseURL = `/user/`;

const challengeAPI = {
    getPreviousTakenTest: () => {
        let requestBody = {
            method: "GET"
        };
        
        return _fetch(baseURL + `Previous/TakenTest`, requestBody);
    },

    getAvailableTest: () => {
        let requestBody = {
            method: "GET"
        };
        
        return _fetch(baseURL + `ScheduleTest/Available`, requestBody);
    },

    startTest: () => {
        return _fetch(baseURL + `start-test`, {
            method: 'POST'
        });
    },

    getTakenTestById: (testId) => {
        let requestBody = {
            method: "GET"
        };
        
        return _fetch(baseURL + `taken-test/${testId}`, requestBody);
    },

    getFirstQuestion: (takenTestId) => {
        let requestBody = {
            method: "GET"
        };
        
        return _fetch(baseURL + `generate-questions/${takenTestId}`, requestBody);
    },

    getQuestionById: (questionId) => {
        let requestBody = {
            method: "GET"
        };
        
        return _fetch(`/question/generate/${questionId}`, requestBody);
    },

    answerQuestion: (param) => {
        let requestBody = {
            body: param,
            method: "POST"
        };

        return _fetch(baseURL + `answer-question`, requestBody);
    },

    getEndTestStatus: (testId, param) => {
        let requestBody = {
            body: param,
            method: "POST"
        };

        return _fetch(`/question/back-to-number/${testId}`, requestBody);
    },

    nextQuestion: (sectionId, questionId) => {
        return _fetch(`/question/next/${sectionId}/${questionId}`, {
            method: "POST"
        });
    },
    previousQuestion: (sectionId, questionId) => {
        return _fetch(`/question/prev/${sectionId}/${questionId}`, {
            method: "POST"
        });
    },

    verifyTokenToStartTest: (body) => {
        return _fetch(`/test/start/verify/token`, {
            method: "POST",
            body
        });
    },

    startTestWithScheduleId: (body) => {
        return _fetch(`/user/start-test-with-id`, {
            method: "POST",
            body
        })
    },

    startTestWithToken: (body) => {
        return _fetch(`/user/start-test-with-token`, {
            method: "POST",
            body
        })
    },

    getTakenQuestionByTakenTest: (id) => {
        return _fetch(`/test/${id}/questions`);
    },

    skipTakenQuestion: (body) => {
        return _fetch(`/test/skip/question`, {
            method: "POST",
            body
        })
    },

    endTest: (id) => {
        return _fetch(`/test/end/${id}`, {
            method: "POST"
        });
    },
    getAssignedTest: () => {
        return _fetch(`/test/assigned`)
    },
    joinTestSession: (id) => {
        return _fetch(`/test/join/${id}`, {
            method: "POST"
        })
    }
};

export default challengeAPI;