import React, { Component, Fragment } from 'react';
import { Switch } from 'react-router-dom';

import MainLayout from './MainLayout';
import EnsureLoginRoute from './Auth/EnsureLoggedInRoute';
import Recording from '../screens/Session/Recording';
import ProctorRecording from '../screens/Session/ProctorRecording';

class App extends Component {
	render() {
		return (
			<Fragment>
				<Switch basename={"/" + window.appSettings.base_name}>
					<EnsureLoginRoute path={`/proctor/:id/stream`} component={ProctorRecording}/>
					<EnsureLoginRoute path={`/test/:token/recording`} component={Recording}/>
					<EnsureLoginRoute path={"/"} component={MainLayout} />
				</Switch>
			</Fragment>
		);
	}
}

export default App;
