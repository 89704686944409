import React from 'react';
import QRCodeResult from './QRCodeResult';
import { Card, Spin } from 'antd';
import { withRouter } from 'react-router-dom'
import { ScheduleAPI } from '../../apis/ScheduleApi';
import TestScheduleQRCodeResult from './TestScheduleQRCodeResult';
import Title from 'antd/lib/typography/Title';
import { color } from '../../helpers/constant';

class TestScheduleAuth extends React.Component {


    state = {
        isFetching: false,
        isValid: false,
        session: {

        }
    }

    componentDidMount() {
        this.validateQueryParams();
    }

    validateQueryParams = () => {
        const { match: { params : { testId, token, userId }}} = this.props;


        this.setState({
            isFetching: true
        })

        ScheduleAPI.verifyURL({
            userId,
            testScheduleId: testId,
            token
        }).then(res => {
            this.setState({
                isValid: true,
                session: res.data
            });
        })
        .catch(err => {
            this.setState({
                isValid: false
            })
        })
        .finally(() => {
            this.setState({
                isFetching: false
            })
        })
    }

    render() {

        const { isValid, isFetching, session } = this.state;

        return <div style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                border: "1px solid #F0F0F0",
                borderRadius: "8px",
                flexDirection: "column"
            }}>
               {
                   isFetching ? 
                   <Spin size="large" /> :
                   (
                        isValid ? 
                        <>
                            <div style={{
                                display :"flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "16px"
                            }}>
                                <div>
                                    <Title>To Proceed</Title>
                                    <ul>
                                        <li>Open HubbedLearn app on your mobile phone</li>
                                        <li>Tap Account {`->`} Web Login</li>
                                        <li>Point your phone camera and scan QR code above</li>
                                    </ul>
                                </div>
                                <TestScheduleQRCodeResult session={session} />
                            </div>
                            <div style={{
                                width: "100%",
                                height: "30px",
                                background: color.primary,
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                            }}></div>
                        </>
                        : <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <h3>Invalid URL</h3>
                            <div>
                                URL not valid! Please refer to the link that emailed to you. if this is the link, please contact our administrator
                                <br />
                                at <a href="mailto:hello@hubbedlearn.com">hello@hubbedlearn.com</a>
                            </div>
                        </div>
                    )
               }
               
            </div>
        </div>
    }
}

export default withRouter(TestScheduleAuth);