import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { Redirect } from 'react-router-dom';
import { AuthAPI } from '../../apis/AuthApi';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import ErrorHandler from '../../helpers/errorHandler';

const ResetPassword = (props) => {

    const [showQRCode, setShowQRCode] = useState(false);
    const [enableOTP, setEnableOTP] = useState(false);
    const [enablePassword, setEnablePassword] = useState(false);
    const [username, setUsername] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [email, setEmail] = useState(null);
    const [form] = Form.useForm();

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const resetPassword = (values) => {

        const { match: { params } } = props;

        const body = {
            email: email,
            token: params.token,
            password: values.password
        }

        AuthAPI.resetPasswordChange(body).then(res => {
            message.success("Process Successfully!");

            props.history.push("/login");
        })
            .catch(err => {
                let error = ErrorHandler(err);
                message.error(error.message);
            });
    }

    useEffect(() => {
        const search = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        if (search.email) {

            setEmail(search.email);
        }

    }, [])

    useEffect(() => {
        form.resetFields();
    }, [email])

    return <div style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#0CB0CF"
    }}>
        <div className='container'>
            <div className='row'>
                <div className='col-12 offset-lg-3 col-lg-6'>
                    <h1 style={{
                        color: "#FFF",
                        fontFamily: "Nunito",
                        fontWeight: "bold"
                    }}>Reset Password</h1>
                    <Card style={{
                        width: "100%",
                        borderRadius: "8px"
                    }}>
                        <Form
                            name="basic"
                            initialValues={{
                                email: email
                            }}
                            onFinish={resetPassword}
                            onFinishFailed={onFinishFailed}
                            hideRequiredMark
                            layout="vertical"
                            form={form}
                        >
                            <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email address',
                                    },
                                ]}
                            >
                                <Input placeholder="Email Address" disabled />
                            </Form.Item>
                            <Form.Item
                                label="New Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <Form.Item
                                label="Confirm Password"
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('The two passwords that you entered do not match!');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" shape="round" size="large" loading={isFetching}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </div>

    </div>
};

export default withRouter(ResetPassword);