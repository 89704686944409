import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, PageHeader } from 'antd';
import Separator from '../Separator';

const Header = props => {
    const { t } = useTranslation(); 
    const { title, enableBackButton, handleBackButton, renderActions } = props;

    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={enableBackButton ? handleBackButton : null}
                title={t(title)}
                extra={[
                    <React.Fragment key={1}>
                        {renderActions ? renderActions() : null}
                    </React.Fragment>
                ]}
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.15)", padding: "12px" }}
            />
        </div>
    );
};

export default Header;