import React, { useState } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { QrcodeOutlined, FormOutlined } from '@ant-design/icons';
import QRCodeResult from './QRCodeResult';
import { Link, Redirect } from 'react-router-dom';
import { AuthAPI } from '../../apis/AuthApi';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

const Login = (props) => {

    const [showQRCode, setShowQRCode] = useState(false);
    const [enableOTP, setEnableOTP] = useState(false);
    const [enablePassword, setEnablePassword] = useState(false);
    const [username, setUsername] = useState("");
    const [isFetching, setIsFetching] = useState(false);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const showQRCodeLogin = () => {
        setShowQRCode(!showQRCode);
    }

    const attempt = (values) => {
        setIsFetching(true)
        AuthAPI.attempt({
            username: values.username.toLowerCase()
        }).then(res => {
            setEnableOTP(res.data.enableOtpInput);
            setEnablePassword(res.data.enablePasswordInput);
            setUsername(res.data.username);
        })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsFetching(false);
            })
    }

    const verifyOTP = (values) => {

        const args = {
            username: username.toLowerCase(),
            password: values.otp
        };

        setIsFetching(true);
        AuthAPI.verifyOTP(args).then(async (res) => {
            loggedIn(res.data.accessToken);
        })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsFetching(false);
            });
    }

    const verifyPassword = (values) => {
        const args = {
            username: username.toLowerCase(),
            password: values.password
        };

        setIsFetching(true);
        AuthAPI.verifyPassword(args).then(async (res) => {
            if (res.data.enableOtpInput) {

                setEnableOTP(res.data.enableOtpInput);
                setEnablePassword(false);
            } else {
                loggedIn(res.data.accessToken);
            }
        })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsFetching(false);
            });
    }

    const loggedIn = (accessToken) => {
        localStorage.setItem('access_token', accessToken);

        console.log('history', props);
    }

    const login = (values) => {

        if (!enableOTP && !enablePassword) {
            return attempt(values);
        }

        if (enableOTP) {
            return verifyOTP(values);
        }

        if (enablePassword) {
            return verifyPassword(values);
        }
    }

    let token = localStorage.getItem('access_token');

    if (token) {
        let search = qs.parse(props.location.search);
        let returnUrl = "/assigned/test";
        if (search.returnUrl) {
            returnUrl = search.returnUrl;
        }

        return <Redirect to={returnUrl} />
    }

    return <div style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#0CB0CF"
    }}>
        <div className='container'>
            <div className='row'>
                <div className='col-12 offset-lg-3 col-lg-6'>
                    <h1 style={{
                        color: "#FFF",
                        fontFamily: "Nunito",
                        fontWeight: "bold",
                        padding: "0px 10px"
                    }}>Login to HubbedLearn</h1>
                    <Card style={{
                        width: "100%",
                        borderRadius: "8px"
                    }} extra={<Button icon={showQRCode ? <FormOutlined /> : <QrcodeOutlined />} shape="round" type="primary" onClick={showQRCodeLogin}></Button>}>
                        {
                            showQRCode
                                ?
                                <QRCodeResult />
                                :
                                <Form
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={login}
                                    onFinishFailed={onFinishFailed}
                                    hideRequiredMark
                                    layout="vertical"
                                >
                                    {
                                        !enablePassword && !enableOTP ?
                                            <Form.Item
                                                label="Username"
                                                name="username"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your username!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Username" />
                                            </Form.Item>
                                            :
                                            null
                                    }

                                    {
                                        enablePassword ?
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                ]}
                                            >
                                                <Input.Password placeholder="Password" />
                                            </Form.Item>
                                            : null
                                    }
                                    {
                                        enableOTP ?
                                            <Form.Item
                                                label="OTP"
                                                name="otp"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your OTP',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="OTP" />
                                            </Form.Item>
                                            : null
                                    }

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" shape="round" size="large" loading={isFetching}>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                        }
                        Forgot your password ? <Link to="/reset-password"><a>Reset Password</a></Link>
                    </Card>
                </div>
            </div>
        </div>
    </div>
};

export default withRouter(Login);