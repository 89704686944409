import React from 'react';
import { Route, Switch } from 'react-router-dom';

import App from './App';
import Login from '../screens/Auth/Login'
import TestScheduleAuth from '../screens/Auth/TestScheduleAuth';
import ResetPassword from '../screens/Auth/ResetPassword';
import ForgetPassword from '../screens/Auth/ForgetPassword';

const Root = () => {
	return (
		<Switch>
			<Route path="/schedule/:userId/:testId/:token" exact>
				<TestScheduleAuth />
			</Route>
			<Route path="/login" exact={true}>
				<Login />
			</Route>
			<Route path="/reset-password" exact={true}>
				<ForgetPassword />
			</Route>
			<Route path="/reset-password/:token" exact={true}>
				<ResetPassword />
			</Route>
			<Route path={"/"}>
				<App />
			</Route>
		</Switch>
	);
};

export default Root;