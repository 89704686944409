const actionTypes = {
	TOGGLE_LOADING: "TOGGLE_LOADING",
	// OPEN_SNACKBAR: "OPEN_SNACKBAR",
	// CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
	// OPEN_SIDEDRAWER: "OPEN_SIDEDRAWER",
	// CLOSE_SIDEDRAWER: "CLOSE_SIDEDRAWER",
	TOGGLE_ACTION: "TOGGLE_ACTION",
	SAVE_TEMPORARY_DATA: "SAVE_TEMPORARY_DATA",
	SAVE_PROFILE: "SAVE_PROFILE",
};

export default actionTypes;
