import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import app from './reducers/index';
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';

import './index.scss';

const store = createStore(app, applyMiddleware(thunk));

ReactDOM.render(
	<Provider store={store}>
		<Router basename={"/"}>
			<Root />
		</Router>
	</Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
