import React from 'react';
import { Layout, Button, Popover, Typography  } from 'antd';
import AgoraRTC from 'agora-rtc-sdk';
import { merge } from 'lodash';
import { withRouter, matchPath } from 'react-router-dom';

import './canvas.css'
import '../../assets/fonts/css/icons.css'
import Header from '../../components/Header';
import { ProctorApi } from '../../apis/ProctorApi';
import ChatRoom from '../Challenge/ChatRoom';
import { MessageFilled } from '@ant-design/icons';
import Separator from '../../components/Separator';
import { AuthAPI } from '../../apis/AuthApi';
import proctorImage from '../../assets/proctoring.svg'
import ChatRoomButton from '../Challenge/ChatButton';

const { Content } = Layout;

const tile_canvas = {
    '1': ['span 12/span 24'],
    '2': ['span 12/span 12/13/25', 'span 12/span 12/13/13'],
    '3': ['span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/19'],
    '4': ['span 6/span 12', 'span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/13'],
    '5': ['span 3/span 4/13/9', 'span 3/span 4/13/13', 'span 3/span 4/13/17', 'span 3/span 4/13/21', 'span 9/span 16/10/21'],
    '6': ['span 3/span 4/13/7', 'span 3/span 4/13/11', 'span 3/span 4/13/15', 'span 3/span 4/13/19', 'span 3/span 4/13/23', 'span 9/span 16/10/21'],
    '7': ['span 3/span 4/13/5', 'span 3/span 4/13/9', 'span 3/span 4/13/13', 'span 3/span 4/13/17', 'span 3/span 4/13/21', 'span 3/span 4/13/25', 'span 9/span 16/10/21'],
  };  

class ProctorRecording extends React.Component {

    constructor(props) {
        super(props);

        this.client = {};
        this.localStream = {};
        this.shareClient = {};
        this.shareStream = {};
        this.state = {
            displayMode: 'tile',
            streamList: [],
            ready: false,
            config: {
              videoProfile: "480p_4",
              channel: "test",
              transcode: "interop",
              attendeeMode: "audience",
              baseMode: "avc",
              appId: "6df3fd4d79a0426e84f06ddaba40543e"
            },
            chatBoxVisible: false,
            channelName: ""
        }
    }

    componentWillMount() {
      this.getStreamChannel();
    }

    getStreamChannel = () => {

        const { location: { pathname }} = this.props

        const match = matchPath(pathname, {
            path: "/proctor/:id/stream",
            exact: true,
            strict: false
        });

        const { params : { id }} = match;

        ProctorApi.getChannelNameByScheduleId({
            testScheduleId: id
        }).then(res => {
            this.setState({
              channelName: res.data.channel
            });
            this.initStream(res.data.channel);
        })
        .catch(err => {
            console.log(err)
        })
    }

    renderHeaderActions = () => {
      return <Popover
        content={<ChatRoom peerId="STUDENT" userId="PROCTOR" />}
        trigger="click"
        visible={this.state.chatBoxVisible}
        onVisibleChange={this.handleChatBoxVisibleChange}
      >
        <Button shape="circle" icon={<MessageFilled />} />
      </Popover>
    }

    handleChatBoxVisibleChange = (chatBoxVisible) => {
      this.setState({
        chatBoxVisible
      })
    }

    initStream = (channelName) => {
      let $ = this.state.config;
        // init AgoraRTC local client
        this.client = AgoraRTC.createClient({ mode: $.transcode })
        this.client.init($.appId, () => {
          console.log("AgoraRTC client initialized")
          this.subscribeStreamEvents()
          this.client.join($.appId, channelName, $.uid, (uid) => {
            console.log("User " + uid + " join channel successfully")
            console.log('At ' + new Date().toLocaleTimeString())
            // create local stream
            // It is not recommended to setState in function addStream
            this.localStream = this.streamInit(uid, $.attendeeMode, $.videoProfile)
            this.localStream.init(() => {
              if ($.attendeeMode !== 'audience') {
                this.addStream(this.localStream, true)
                this.client.publish(this.localStream, err => {
                  console.log("Publish local stream error: " + err);
                })
              }
              this.setState({ readyState: true })
            },
              err => {
                console.log("getUserMedia failed", err)
                this.setState({ readyState: true })
              })
          })
        })
      }

      componentDidUpdate() {

        // rerendering
        this.state.streamList.map((item, index) => {

          let canvas = document.querySelector('#stream-'+item.user.id);
          item.streams.map(stream => {
            let id = stream.getId()
            let dom = document.querySelector('#ag-item-' + id)
            if (!dom) {
              dom = document.createElement('section')
              dom.setAttribute('id', 'ag-item-' + id)
              dom.setAttribute('class', 'proctor-screen-item')
              canvas.appendChild(dom)
              stream.play('ag-item-' + id)
            }
    
            stream.player.resize && stream.player.resize();
          })
        })
      }
      

      renderStreamingScreen = () => {
        const { streamList } = this.state;

        console.log(streamList);
      }

      componentWillUnmount () {
        this.client && this.client.unpublish(this.localStream)
        this.localStream && this.localStream.close()
        this.client && this.client.leave(() => {
          console.log('Client succeed to leave.')
        }, () => {
          console.log('Client failed to leave.')
        })
      }

      streamInit = (uid, attendeeMode, videoProfile, config) => {
        let defaultConfig = {
          streamID: uid,
          audio: true,
          video: true,
          screen: false
        }
    
        switch (attendeeMode) {
          case 'audio-only':
            defaultConfig.video = false
            break;
          case 'audience':
            defaultConfig.video = false
            defaultConfig.audio = false
            break;
          default:
          case 'video':
            break;
        }
    
        let stream = AgoraRTC.createStream(merge(defaultConfig, config))
        stream.setVideoProfile(videoProfile)
        return stream
      }
    
      subscribeStreamEvents = () => {
        let rt = this
        rt.client.on('stream-added', function (evt) {
          let stream = evt.stream
          rt.client.subscribe(stream, function (err) {
            console.log("Subscribe stream failed", err)
          })
        })
    
        rt.client.on('peer-leave', function (evt) {
          rt.removeStream(evt.uid)
        })
    
        rt.client.on('stream-subscribed', function (evt) {
          let stream = evt.stream;
          rt.addStream(stream)
        })
    
        rt.client.on("stream-removed", function (evt) {
          let stream = evt.stream;
          rt.removeStream(stream.getId())
        })
      }
      
      removeStream = (uid) => {

        let alteredStreamList = [...this.state.streamList];

        this.state.streamList.map((item, index) => {
          item.streams.map((stream, innerIndex) => {
            if (stream.getId() === uid) {
              stream.close()
              let element = document.querySelector('#ag-item-' + uid)
              if (element) {
                element.parentNode.removeChild(element)
              }
              let tempList = [...item.streams]
              tempList.splice(innerIndex, 1)
              item.streams = tempList;
            }
          });

          console.log(item, 'streamingitem');

          if (!item.streams.length) {
            alteredStreamList = [...this.state.streamList];
            alteredStreamList.splice(index, 1)
          }
        })

        this.setState({
          streamList: alteredStreamList
        });
      }
    
      addStream = (stream, push = false) => {

        let id = stream.getId();

        AuthAPI.getUserFromChannel({
          uid: id,
          channelName: this.state.channelName
        })
        .then((res) => {
          const { streamList } = this.state;
          const { id } = res.data;

          let existedUserIndex = streamList.findIndex(x => x.id === id);

          if (existedUserIndex !== -1) {
            let repeatition = streamList[existedUserIndex].streams.some(item => {
              return item.getId() === stream.getId()
            });

            if (repeatition) {
              return;
            }

            streamList[existedUserIndex].streams.push(stream)
          } else {
            let newUser = {
              id: id,
              user: res.data,
              streams: [
                stream
              ]
            }

            streamList.push(newUser);
          }

          this.setState({
            streamList
          }, () => {
            console.log(this.state.streamList);
          })
        })
        .catch(err => {
          console.log(err);
        })
      }
    
      handleCamera = (e) => {
        e.currentTarget.classList.toggle('off')
        this.localStream.isVideoOn() ?
          this.localStream.disableVideo() : this.localStream.enableVideo()
      }
    
      handleMic = (e) => {
        e.currentTarget.classList.toggle('off')
        this.localStream.isAudioOn() ?
          this.localStream.disableAudio() : this.localStream.enableAudio()
      }
      
      hideRemote = (e) => {
        if (e.currentTarget.classList.contains('disabled') || this.state.streamList.length <= 1) {
          return
        }
        let list
        let id = this.state.streamList[this.state.streamList.length - 1].getId()
        list = Array.from(document.querySelectorAll(`.ag-item:not(#ag-item-${id})`))
        list.map(item => {
          if (item.style.display !== 'none') {
            item.style.display = 'none'
          }
          else {
            item.style.display = 'block'
          }
        })
    
      }

      handleExit = (e) => {
        if (e.currentTarget.classList.contains('disabled')) {
          return
        }
        try {
          this.client && this.client.unpublish(this.localStream)
          this.localStream && this.localStream.close()
          this.client && this.client.leave(() => {
            console.log('Client succeed to leave.')
          }, () => {
            console.log('Client failed to leave.')
          })
        }
        finally {
          this.setState({ readyState: false })
          this.client = null
          this.localStream = null
          // redirect to index
          window.location.hash = ''
        }
      }
    

    render() {

        const { config, streamList } = this.state;

        const style = {
            display: 'grid',
            gridGap: '10px',
            alignItems: 'center',
            justifyItems: 'center',
            gridTemplateRows: 'repeat(12, auto)',
            gridTemplateColumns: 'repeat(24, auto)',
            height: "calc(100vh - 77px)",
            width: "100%"
          }
          const videoControlBtn = config.attendeeMode === 'video' ?
            (<span
              onClick={this.handleCamera}
              className="ag-btn videoControlBtn"
              title="Enable/Disable Video">
              <i className="ag-icon ag-icon-camera"></i>
              <i className="ag-icon ag-icon-camera-off"></i>
            </span>) : ''
      
          const audioControlBtn = config.attendeeMode !== 'audience' ?
            (<span
              onClick={this.handleMic}
              className="ag-btn audioControlBtn"
              title="Enable/Disable Audio">
              <i className="ag-icon ag-icon-mic"></i>
              <i className="ag-icon ag-icon-mic-off"></i>
            </span>) : ''
      
          const switchDisplayBtn = (
            <span
              onClick={this.switchDisplay}
              className={this.state.streamList.length > 4 ? "ag-btn displayModeBtn disabled" : "ag-btn displayModeBtn"}
              title="Switch Display Mode">
              <i className="ag-icon ag-icon-switch-display"></i>
            </span>
          )
          const hideRemoteBtn = (
            <span
              className={this.state.streamList.length > 4 || this.state.displayMode !== 'pip' ? "ag-btn disableRemoteBtn disabled" : "ag-btn disableRemoteBtn"}
              onClick={this.hideRemote}
              title="Hide Remote Stream">
              <i className="ag-icon ag-icon-remove-pip"></i>
            </span>
          )
          const exitBtn = (
            <span
              onClick={this.handleExit}
              className={this.state.readyState ? 'ag-btn exitBtn' : 'ag-btn exitBtn disabled'}
              title="Exit">
              <i className="ag-icon ag-icon-leave"></i>
            </span>
          )
      

        return (
          <Layout>
            <Content>
              <Header title="HubbedLearn - Proctoring" />
              {/* <div id="ag-canvas" style={style}>
                
                <div className="ag-btn-group">
                  {exitBtn}
                  {switchDisplayBtn}
                  {hideRemoteBtn}
                </div>
              </div> */}
              <div style={{
                flexDirection: "column",
                padding: "16px"
              }}>
                  {
                    streamList.map(item => {
                      return (
                        <>
                          <div style={{
                            display: "flex",
                            width: "100%",
                            height: "240px",
                            padding: "12px",
                            justifyContent: "center",
                            flexDirection: "column"
                          }}>
                            <div style={{
                              display: "flex"
                            }}>{item.user.name}</div>
                            <div style={{
                              display: "flex",
                              flexDirection: "row"
                            }}>
                                <div id={`stream-${item.user.id}`} style={{
                                  display: "flex",
                                  flexDirection: "row"
                                }}>
                                 
                                </div>
                                
                                
                                <div style={{
                                  height: "180px",
                                  width: "320px",
                                  display: "flex"
                                }}>
                                  <ChatRoomButton userId={"PROCTOR"} peerId={item.user.id.toString()} counterPartName={item.user.name}/>
                                </div>
                            </div>
                          </div>
                          <Separator variant="vertical" size={16} />
                        </>
                      )
                    })
                  }
                  {
                    !streamList.length ? <div style={{
                      height: "calc(100vh - 97px)",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column"
                    }}>
                      <img src={proctorImage} alt="img" style={{ width: "360px" }}/>
                      <Separator variant="vertical"/>
                      <Typography>No Stream Found</Typography>
                    </div> : null
                  }
              </div>
            </Content>
			    </Layout>
        )
    }
}

export default ProctorRecording;