import actionTypes from './actionType';

export const saveProfile = (profile) => ({
	type: actionTypes.SAVE_PROFILE,
	profile,
});

export const toggleLoading = () => ({
	type: actionTypes.TOGGLE_LOADING,
});

export const toggleAction = (isActionActive) => ({
	type: actionTypes.TOGGLE_ACTION,
	isActionActive,
});

export const saveTemporaryData = (temporaryData) => ({
	type: actionTypes.SAVE_TEMPORARY_DATA,
	temporaryData,
});

// export const openSnackbar = (
// 	message,
// 	severity,
// 	snackbarPosition = { vertical: "bottom", horizontal: "center" }
// ) => ({
// 	type: actionTypes.OPEN_SNACKBAR,
// 	message,
// 	severity,
// 	snackbarPosition,
// });

// export const closeSnackbar = () => ({
// 	type: actionTypes.CLOSE_SNACKBAR,
// });

// export const openSideDrawer = () => ({
// 	type: actionTypes.OPEN_SIDEDRAWER,
// });

// export const closeSideDrawer = () => ({
// 	type: actionTypes.CLOSE_SIDEDRAWER,
// });
