import { MessageOutlined } from '@ant-design/icons';
import { Badge, Button, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react'
import Separator from '../../components/Separator';
import AgoraRTM from 'agora-rtm-sdk';

class ChatRoomButton extends React.Component {

    state = {
        visible: false,
        message : "",
        messages: []
    }

    close = () => {
        this.setState({
            visible: false
        })
    }

    open = () => {
        const {messages} = this.state;

        let newMessages = [...messages];

        newMessages.map(item => {
            return item.isRead = true;
        })

        this.setState({
            visible: true,
            messages: newMessages
        })
    }

    componentDidMount() {
        const { userId, peerId } = this.props;

        let alteredUserId = userId ? userId.toString() : "";

        this.client =  AgoraRTM.createInstance("6df3fd4d79a0426e84f06ddaba40543e");

        this.client.login({ uid: alteredUserId }).then(() => {
            console.log('AgoraRTM client login success');
        }).catch(err => {
            console.log('AgoraRTM client login failure', err);
        });

        this.client.on('MessageFromPeer', (event, incomingPeerId, messageProps) => {

            if (peerId == incomingPeerId) {
                const { text } = event;
                const { messages } = this.state;

                let msg = {
                    isMyself: false,
                    message: text,
                    isRead: false
                };

                messages.push(msg);
                
                this.setState({messages});
            }
        });
    }

    sendMessage = (event) => {

        const { peerId } = this.props;
        let alteredPeerId = peerId ? peerId.toString() : "";
        let message = event.target.value;
        const { messages } = this.state;

        this.client.sendMessageToPeer({
            text: message
        }, alteredPeerId, {enableHistoricalMessaging: true, enableOfflineMessaging: true})
        .then(res => {
            let msg = {
                isMyself: true,
                message: message,
                isRead: true
            };

            messages.push(msg);
            this.setState({messages, message: ""});
        })
        .catch(err => {
            console.error(err);
        })
    }

    onChange = (event) => {

        let message = event.target.value;
        this.setState({
            message: message
        })
    }

    render() {

        const { visible, messages, message } = this.state;

        return (
            <>
                <Badge count={messages.filter(x => !x.isRead).length} overflowCount={99}>
                    <Button shape="round" icon={<MessageOutlined />} onClick={this.open}>Chat</Button>
                </Badge>
                <Modal
                    title="Chatroom"
                    visible={visible}
                    onCancel={this.close}
                    footer={[
                        <Button key="back" onClick={this.close}>
                            Close
                        </Button>
                    ]}
                >
                    <div style={{
                        width: "100%",
                        display: "flex"
                    }}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}>
                            <div style={{ padding: "8px", height: "400px", overflowY: "auto"}}>
                                {
                                    messages.map(item => {
                                        return <>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: item.isMyself ? "flex-end" : "flex-start"
                                            }}>
                                                <div style={{
                                                    padding: "4px",
                                                    borderRadius: "4px",
                                                    border: item.isMyself ? "1px solid #F0F0F0" : "1px solid #0CB0CF",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    backgroundColor: item.isMyself ? "#F0F0F0" : "#0CB0CF",
                                                    color: item.isMyself ? "#000" : "#FFF",
                                                    maxWidth: "45%",
                                                    minWidth: "20%"
                                                }}>
                                                    <div><strong>{item.isMyself ? "Me": this.props.counterPartName}</strong></div>
                                                    {item.message}
                                                </div>
                                            </div>
                                            <Separator variant="vertical" size={8}/>
                                        </>
                                    })
                                }
                            </div>
                            <Input placeholder="Enter your message" onPressEnter={this.sendMessage} value={message} onChange={this.onChange}/>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default ChatRoomButton;