import React from 'react';
import { Layout, Button, Tag,  } from 'antd';
import AgoraRTC from 'agora-rtc-sdk';
import { merge } from 'lodash';
import { withRouter, matchPath } from 'react-router-dom';

import './canvas.css'
import '../../assets/fonts/css/icons.css'
import Header from '../../components/Header';
import { ScheduleAPI } from '../../apis/ScheduleApi';
import Separator from '../../components/Separator';
import { AuthAPI } from '../../apis/AuthApi';
import Modal from 'antd/lib/modal/Modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;

const tile_canvas = {
    '1': ['span 12/span 24'],
    '2': ['span 12/span 12/13/25', 'span 12/span 12/13/13'],
    '3': ['span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/19'],
    '4': ['span 6/span 12', 'span 6/span 12', 'span 6/span 12', 'span 6/span 12/7/13'],
    '5': ['span 3/span 4/13/9', 'span 3/span 4/13/13', 'span 3/span 4/13/17', 'span 3/span 4/13/21', 'span 9/span 16/10/21'],
    '6': ['span 3/span 4/13/7', 'span 3/span 4/13/11', 'span 3/span 4/13/15', 'span 3/span 4/13/19', 'span 3/span 4/13/23', 'span 9/span 16/10/21'],
    '7': ['span 3/span 4/13/5', 'span 3/span 4/13/9', 'span 3/span 4/13/13', 'span 3/span 4/13/17', 'span 3/span 4/13/21', 'span 3/span 4/13/25', 'span 9/span 16/10/21'],
  };  

class Recording extends React.Component {

    constructor(props) {
        super(props);

        this.client = {};
        this.screenClient = {};
        this.localStream = {};
        this.screenStream = {};
        this.shareClient = {};
        this.shareStream = {};
        this.state = {
            visible: false,
            displayMode: 'pip',
            streamList: [],
            ready: false,
            config: {
              videoProfile: "480p_4",
              channel: "test",
              transcode: "interop",
              attendeeMode: "video",
              baseMode: "avc",
              appId: "6df3fd4d79a0426e84f06ddaba40543e"
          }
        }
    }

    componentWillMount() {
      this.getStreamChannel();
    }

    getStreamChannel = () => {

      const { token, sharingCode } = this.props;

      if (token) {
        ScheduleAPI.getStreamingChannelName({
          token
        }).then(res => {
          this.initStream(res.data.channel);
          this.initScreenStream(res.data.channel);
        })
        .catch(err => {
          console.log(err)
        })
      } else if (sharingCode) {
        ScheduleAPI.getStreamingChannelNameBySchedule({
          code: sharingCode
        }).then(res => {
          this.initStream(res.data.channel);
          this.initScreenStream(res.data.channel);
        })
        .catch(err => {
          console.log(err)
        })
      }
    }
    
    renderHeaderActions = () => {
      return <Button>
        Back to dashboard
      </Button>
    }

    initStream = (channelName) => {
      let $ = this.state.config;
        // init AgoraRTC local client
        this.client = AgoraRTC.createClient({ mode: $.transcode })
        this.client.init($.appId, () => {
          console.log("AgoraRTC client initialized")
          this.subscribeStreamEvents()
          this.client.join($.appId, channelName, $.uid, (uid) => {

            // send mapping
            AuthAPI.mapUserToChannel({
              channelName, 
              uuid: uid
            }).then(() => {
                this.localStream = this.streamInit(uid, $.attendeeMode, $.videoProfile)
                this.localStream.init(() => {
                  if ($.attendeeMode !== 'audience') {
                    this.addStream(this.localStream, true)
                    this.client.publish(this.localStream, err => {
                      console.log("Publish local stream error: " + err);
                    })
                  }
                  this.setState({ readyState: true })
                  },
                  err => {
                    console.log("getUserMedia failed", err)
                    this.setState({ readyState: true })
                  }
                )
            })
            .catch(err => {
              console.log(err);
            })
          })
        })
      }

      initScreenStream = (channelName) => {
        let $ = this.state.config;
        // init AgoraRTC local client
        this.screenClient = AgoraRTC.createClient({ mode: $.transcode })
        this.screenClient.init($.appId, () => {
          console.log("AgoraRTC client initialized")
          this.subscribeStreamEvents()
            

          this.screenClient.join($.appId, channelName, $.uid, (uid) => {

            AuthAPI.mapUserToChannel({
              channelName, 
              uuid: uid
            }).then(() => {
              this.screenStream = this.screenInit(uid);
              this.screenStream.init(() => {
                this.screenClient.publish(this.screenStream, err => {
                  console.log("Publish screen stream error: " + err);
                })
              }, err => {
                console.log("getUserMedia failed", err)
                this.setState({ readyState: true })
              })
            }).catch(err => {
              console.log(err);
            })
          })
        })
      }

      componentDidUpdate() {
        // rerendering
        let canvas = document.querySelector('#ag-canvas')
        // pip mode (can only use when less than 4 people in channel)
        if (this.state.displayMode === 'pip') {
          let no = this.state.streamList.length;
          this.state.streamList.map((item, index) => {
            let id = item.getId()
            let dom = document.querySelector('#ag-item-' + id)
            if (!dom) {
              dom = document.createElement('section')
              dom.setAttribute('id', 'ag-item-' + id)
              dom.setAttribute('class', 'ag-item')
              canvas.appendChild(dom)
              item.play('ag-item-' + id)
            }
            if (index === no - 1) {
              dom.setAttribute('style', `grid-area: span 12/span 24/13/25`)
            }
            else {
              dom.setAttribute('style', `grid-area: span 3/span 4/${4 + 3 * index}/25;
                        z-index:1;width:calc(100% - 20px);height:calc(100% - 20px)`)
            }
    
            item.player.resize && item.player.resize();
          })
        }
        // tile mode
        else if (this.state.displayMode === 'tile') {
          let no = this.state.streamList.length
          this.state.streamList.map((item, index) => {
            let id = item.getId()
            let dom = document.querySelector('#ag-item-' + id)
            if (!dom) {
              dom = document.createElement('section')
              dom.setAttribute('id', 'ag-item-' + id)
              dom.setAttribute('class', 'ag-item')
              canvas.appendChild(dom)
              item.play('ag-item-' + id)
            }
            dom.setAttribute('style', `grid-area: ${tile_canvas[no][index]}`)
            item.player.resize && item.player.resize()
    
    
          })
        }
        // screen share mode (tbd)
        else if (this.state.displayMode === 'share') {
    
        }
      }
    

      componentWillUnmount () {
        this.client && this.client.unpublish(this.localStream)
        this.localStream && this.localStream.close()
        this.client && this.client.leave(() => {
          console.log('Client succeed to leave.')
        }, () => {
          console.log('Client failed to leave.')
        })
      }

      streamInit = (uid, attendeeMode, videoProfile, config) => {
        let defaultConfig = {
          streamID: uid,
          audio: false,
          video: true,
          screen: false
        }
    
        switch (attendeeMode) {
          case 'audio-only':
            defaultConfig.video = false
            break;
          case 'audience':
            defaultConfig.video = false
            defaultConfig.audio = false
            break;
          default:
          case 'video':
            break;
        }
    
        let stream = AgoraRTC.createStream(merge(defaultConfig, config))
        stream.setVideoProfile(videoProfile)
        return stream
      }

      screenInit = (uid) => {
        let defaultConfig = {
          streamID: uid,
          audio: false,
          video: false,
          screen: true,
        }
    
        let stream = AgoraRTC.createStream(defaultConfig)
        stream.setScreenProfile("720p_2")
        return stream
      }
    
      subscribeStreamEvents = () => {
        let rt = this
        rt.client.on('stream-added', function (evt) {
          let stream = evt.stream
          console.log("New stream added: " + stream.getId())
          console.log('At ' + new Date().toLocaleTimeString())
          console.log("Subscribe ", stream)
          rt.client.subscribe(stream, function (err) {
            console.log("Subscribe stream failed", err)
          })
        })
    
        rt.client.on('peer-leave', function (evt) {
          console.log("Peer has left: " + evt.uid)
          console.log(new Date().toLocaleTimeString())
          console.log(evt)
          rt.removeStream(evt.uid)
        })
    
        rt.client.on('stream-subscribed', function (evt) {
          let stream = evt.stream
          console.log("Got stream-subscribed event")
          console.log(new Date().toLocaleTimeString())
          console.log("Subscribe remote stream successfully: " + stream.getId())
          console.log(evt)
          rt.addStream(stream)
        })
    
        rt.client.on("stream-removed", function (evt) {
          let stream = evt.stream
          console.log("Stream removed: " + stream.getId())
          console.log(new Date().toLocaleTimeString())
          console.log(evt)
          rt.removeStream(stream.getId())
        })
      }
      
      removeStream = (uid) => {
        this.state.streamList.map((item, index) => {
          if (item.getId() === uid) {
            item.close()
            let element = document.querySelector('#ag-item-' + uid)
            if (element) {
              element.parentNode.removeChild(element)
            }
            let tempList = [...this.state.streamList]
            tempList.splice(index, 1)
            this.setState({
              streamList: tempList
            })
          }
    
        })
      }
    
      addStream = (stream, push = false) => {
        let repeatition = this.state.streamList.some(item => {
          return item.getId() === stream.getId()
        })
        if (repeatition) {
          return
        }
        if (push) {
          this.setState({
            streamList: this.state.streamList.concat([stream])
          })
        }
        else {
          this.setState({
            streamList: [stream].concat(this.state.streamList)
          })
        }
        console.log('stream added', stream.getId());
        
        this.hideRemote();
      }
    
      handleCamera = (e) => {
        e.currentTarget.classList.toggle('off')
        this.localStream.isVideoOn() ?
          this.localStream.disableVideo() : this.localStream.enableVideo()
      }
    
      handleMic = (e) => {
        e.currentTarget.classList.toggle('off')
        this.localStream.isAudioOn() ?
          this.localStream.disableAudio() : this.localStream.enableAudio()
      }
      
      hideRemote = () => {
        if (this.state.streamList.length <= 1) {
          return
        }
        let list
        let id = this.state.streamList[this.state.streamList.length - 1].getId()
        list = Array.from(document.querySelectorAll(`.ag-item:not(#ag-item-${id})`))
        list.map(item => {
          if (item.style.display !== 'none') {
            item.style.display = 'none'
          }
          else {
            item.style.display = 'block'
          }
        })
    
      }

      handleExit = (e) => {
        if (e.currentTarget.classList.contains('disabled')) {
          return
        }
        try {
          this.client && this.client.unpublish(this.localStream)
          this.localStream && this.localStream.close()
          this.client && this.client.leave(() => {
            console.log('Client succeed to leave.')
          }, () => {
            console.log('Client failed to leave.')
          })
        }
        finally {
          this.setState({ readyState: false })
          this.client = null
          this.localStream = null
          // redirect to index
          window.location.hash = ''
        }
      }
    
    buttonOnClick = () => {
      this.setState({
        visible: true
      })
    }

    modalClose = () => {
      this.setState({
        visible: false
      })
    }

    render() {

        const { visible } = this.state;

        const style = {
            display: 'grid',
            gridGap: '10px',
            alignItems: 'center',
            justifyItems: 'center',
            gridTemplateRows: 'repeat(12, auto)',
            gridTemplateColumns: 'repeat(24, auto)',
        }
      

        return (
          <>
            <Button shape="round" icon={<ExclamationCircleOutlined />} onClick={this.buttonOnClick}>Screen is being monitored</Button>
            <Modal visible={visible} title="Screen Monitored" onCancel={this.modalClose} forceRender>
              <div style={{ flexDirection: "column", display: "flex" }}>
                <div>
                  <Tag color="green">STREAM</Tag>
                </div>
                <Separator variant="vertical"/>
                <div id="ag-canvas" style={style}>
                </div>
                <Separator variant="vertical" />
                <span style={{textAlign: "center"}}>
                  Your Test is being streamed to proctors
                </span>
              </div>
            </Modal>
          </>
        )
    }
}

export default withRouter(Recording);