function Loading(props) {
    if (props.error) {
        return "Error! Please refresh your page";
    } else if (props.pastDelay) {
        return "Loading ...";
    } else {
        return null;
    }
};

export default Loading;