import React, { useState } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { Redirect } from 'react-router-dom';
import { AuthAPI } from '../../apis/AuthApi';
import { withRouter } from 'react-router-dom';

const ResetPassword = (props) => {

    const [showQRCode, setShowQRCode] = useState(false);
    const [enableOTP, setEnableOTP] = useState(false);
    const [enablePassword, setEnablePassword] = useState(false);
    const [username, setUsername] = useState("");
    const [isFetching, setIsFetching] = useState(false);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const resetPassword = (values) => {
        AuthAPI.resetPassword(values).then(res => {
            alert("success");
        })
            .catch(err => {
                console.log(err);
            })
    }

    return <div style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#0CB0CF"
    }}>
        <div className='container'>
            <div className='row'>
                <div className='col-12 offset-lg-3 col-lg-6'>
                    <h1 style={{
                        color: "#FFF",
                        fontFamily: "Nunito",
                        fontWeight: "bold"
                    }}>Reset Password</h1>
                    <Card style={{
                        width: "100%",
                        borderRadius: "8px"
                    }}>
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={resetPassword}
                            onFinishFailed={onFinishFailed}
                            hideRequiredMark
                            layout="vertical"
                        >
                            <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email address',
                                    },
                                ]}
                            >
                                <Input placeholder="Email Address" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" shape="round" size="large" loading={isFetching}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </div>
    </div>
};

export default withRouter(ResetPassword);