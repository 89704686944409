import React from 'react';
import AgoraRTM from 'agora-rtm-sdk';
import TextArea from 'antd/lib/input/TextArea';
import { Input } from 'antd';
import Separator from '../../components/Separator';

class ChatRoom extends React.Component {

    state = {
        messages: [],
        message: ""
    }
    client = null;


    componentDidMount() {

        const { userId, peerId } = this.props;

        let alteredUserId = userId ? userId.toString() : "";

        this.client =  AgoraRTM.createInstance("6df3fd4d79a0426e84f06ddaba40543e");

        this.client.on('ConnectionStateChanged', (newState, reason) => {
            console.log('on connection state changed to ' + newState + ' reason: ' + reason);
        });

        this.client.login({ uid: alteredUserId }).then(() => {
            console.log('AgoraRTM client login success');
        }).catch(err => {
            console.log('AgoraRTM client login failure', err);
        });

        this.client.on('MessageFromPeer', (event, incomingPeerId, messageProps) => {

            if (peerId == incomingPeerId) {
                const { text } = event;
                const { messages } = this.state;

                let msg = {
                    isMyself: false,
                    message: text,
                };

                messages.push(msg);
                
                this.setState({messages});
            }
            
        });
    }

    sendMessage = (event) => {

        const { peerId } = this.props;
        let alteredPeerId = peerId ? peerId.toString() : "";
        let message = event.target.value;
        const { messages } = this.state;

        this.client.sendMessageToPeer({
            text: message
        }, alteredPeerId, {enableHistoricalMessaging: true, enableOfflineMessaging: true})
        .then(res => {
            let msg = {
                isMyself: true,
                message: message,
            };

            messages.push(msg);
            this.setState({messages, message: ""});
        })
        .catch(err => {
            console.error(err);
        })
    }

    onChange = (event) => {

        let message = event.target.value;
        this.setState({
            message: message
        })
    }



    render() {

        const { messages, message } = this.state;

        return <div style={{
            width: "100%",
            display: "flex"
        }}>
            <div style={{
                width: "100%",
                borderRadius: "4px",
                border: "1px solid black",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <div style={{ padding: "8px"}}>
                    {
                        messages.map(item => {
                            return <>
                                <div style={{
                                    padding: "4px",
                                    borderRadius: "4px",
                                    border: item.isMyself ? "1px solid black" : "1px solid blue",
                                    display: "flex",
                                    flexDirection: "column",
                                    
                                }}>
                                    <div></div>
                                    {item.message}
                                </div>
                                <Separator variant="vertical" size={4}/>
                            </>
                        })
                    }
                </div>
                <Input placeholder="Enter your message" onPressEnter={this.sendMessage} value={message} onChange={this.onChange}/>
            </div>
        </div>
    }
}

export default ChatRoom;