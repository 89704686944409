import {_fetch} from '../helpers/_fetch';

const url ='/auth';

export const AuthAPI = {
    showQRCode: () => {
        return _fetch(`${url}/qrcode`);
    },
    loginWithQRCode: (body) => {
        return _fetch(`${url}/qrcode/login`, {
            method: 'POST',
            body
        });
    },
    attempt: (body) => {
        return _fetch(`${url}/attempt`, {
            method: 'POST',
            body: body
        });
    },
    verifyOTP: (body) => {
        return _fetch(`${url}/verify/otp`, {
            method: 'POST',
            body: body
        });
    },
    verifyPassword: (body) => {
        return _fetch(`${url}/verify/password`, {
            method: 'POST',
            body: body
        });
    },
    mapUserToChannel : (body) => {
        return _fetch('/test/streaming/add-user-to-channel', {
            method: "POST",
            body
        })
    },
    getUserFromChannel : (body) => {
        return _fetch('/test/streaming/get-user-from-channel', {
            method: "POST",
            body
        })
    },
    getProfile: () => {
        return _fetch('/user/profile');
    },
    resetPassword: (body) => {
        return _fetch("auth/reset-password", {
            method: "POST",
            body
        });
    },
    resetPasswordChange: (body) => {
        return _fetch("auth/reset-password/change", {
            method: "POST",
            body
        });
    }
};