import React from 'react'
import QRCode from 'qrcode.react';
import Echo from 'laravel-echo';
import socketIOClient from 'socket.io-client';
import Separator from '../../components/Separator';
import { Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { ScheduleAPI } from '../../apis/ScheduleApi';

class TestScheduleQRCodeResult extends React.Component {
    
    state = {
        isFetching : false,
        isExpired: false,
        isError: false,
        errorMessage: ""
    }

    componentDidMount() {
        this.initListener();
    }

    validateIfScheduleTimeMatch = (token) => {
        this.setState({
            isFetching :true
        })

        const { history } = this.props;

        ScheduleAPI.validateScheduleTime(token).then(res => {
            history.push(`/challenge/${token}/start`);
        })
        .catch(err => {
            this.setState({
                isError: true,
                errorMessage: "Test session is not available, it is either expired or has not started yet."
            })
        })
        .finally(() => {
            this.setState({
                isFetching: false
            })
        })
    }

    initListener = () => {
        const { history, session } = this.props;

        window.io = socketIOClient;

        const echo = new Echo({
            broadcaster: "socket.io",
            host: "https://admin.hubbedlearn.com"
        });


        echo.channel("test.channel."+ session.token).listen(".test.on.scan", (e) => {

            this.setState({
                isFetching: true
            });

            const { session } = e;

            ScheduleAPI.auth({
                token: session.token,
                userId: session.user_id
            })
            .then(res => {
                localStorage.setItem('access_token', res.data.accessToken);

                this.validateIfScheduleTimeMatch(session.token);
            })
            .catch(err => {
                this.setState({
                    isError: true,
                    errorMessage: "Session authentication failed."
                })
            })
            .finally(() => {
                this.setState({
                    isFetching: false
                })
            })
        });
    }

    render() {

        const { isFetching, isError, errorMessage } = this.state;
        const { session } = this.props;

        const value = {
            type: "session",
            value: session.token
        }

        const stringifiedValue = JSON.stringify(value);

        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            {
                isError 
                ?
                errorMessage
                :
                <React.Fragment>
                    <div style={{
                        position: 'relative'
                    }}>
                        <QRCode value={stringifiedValue} size={320} includeMargin/>
                        {
                            isFetching ?
                            <div style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: 320,
                                width: 320,
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(0,0,0,0.1)',
                                color: 'white'
                            }}>
                                <Spin size="large" spinning={isFetching}/>
                            </div>
                            :null
                        }
                    </div>
                </React.Fragment>
            }
        </div>;
    }
}

export default withRouter(TestScheduleQRCodeResult);